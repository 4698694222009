.main-content{
    flex: 7;
    flex-direction: row;
}

.title
{  
  font-family : Roboto;
  font-size : 17px;
  color : #757575;
  color : rgb(117, 117, 117);
  text-decoration: none;
  
}